import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, reactive, ref} from 'vue';
import {getFunc, getListFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_CRSE_DIST_APPLY,
  ACT_GET_CRSE_DIST_REGULAR_LIST,
} from '@/store/modules/course/course';
import {
  ACT_GET_LEARN_ACCUM_OBJECT_RESULT,
  ACT_GET_LEARN_ACCUM_OBJECT_RESULT_LIST,
  ACT_GET_LEARN_OBJECT_ADDED,
  ACT_GET_LEARN_OBJECT_MASTER_LIST,
  ACT_INSERT_LEARN_RESULT,
} from '@/store/modules/learn/learn';
import {
  collectionCheck,
  getItem,
  getItems,
  isSuccess,
  lengthCheck,
  timestampToDateFormat,
  timestampToDatePart,
} from '@/assets/js/util';
import {
  canDo,
  cmsTypes,
  enableLearn,
  getTutors,
  isProgress,
  lrnObjTyCds,
} from '@/assets/js/modules/learn/learn-common';
import {sortObjectItems} from '@/assets/js/modules/learn/learn-regular-sort';
import ApiService from '@/assets/js/api.service';

export const learnRegularMainSetup = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const {showMessage, showLoading, hideLoading} = useAlert();

  const distCrseSn = computed(() => route.params.distCrseSn);
  const currentDate = ref(new Date());
  const isMobile = computed(() => store.state.auth.isMobile);

  if (distCrseSn.value == '204095') {
    showMessage("가계여신 직무수행교육을 수료했음에도<br> KB모바일브랜치 LMS발송이 되지 않는 경우<br><br> MyStar 화면번호 : <span style='color: red;'>MEG75031001</span> 거래에서<br> 개인정보 수집ㆍ이용ㆍ제공 동의 바랍니다.<br>(동의 후 익영업일부터 이용 가능)");
  }

  console.log("test");

  const params = reactive({
    distCrseSn: 0,
    crseMstSn: 0,
    classCateSn: 0,
    classCateNm: '',
    mediaCateSn: 0,
    mediaCateNm: '',
    orgnzCdDcd: '',
    orgnzNm: '',
    prvdrSn: 0,
    prvdrNm: '',
    introDivCdDcd: '',
    introDivNm: '',
    crseNm: '',
    crseSumup: '',
    crseDtl: '',
    exptAblty: '',
    crseLvl: '',
    classUseYn: '',
    classCnt: 0,
    bgngDt: 0,
    endDt: 0,
    thumb: '',
    mstThumb: '',
    etcThumb: '',
    prevUrl: '',
    crseMlg: 0,
    totLrnPerid: 0,
    totLrnHr: 0,
    fnshCondYn: '', // 수료조건 여부
    rfltRateUseYn: '', // 반영 비율 사용 여부
    fnshCrtrUseYn: '', // 수료 기준 사용 여부
    fnshCrtrScore: 0, // 수료 기준 점수
    lrnTrgtGrpMstSn: 0, // 학습 그룹
    eduTrnctCdDcd: '', // 연수원 DCD
    eduTrnctNm: '', // 연수원
    loc: '', // 장소
    splCrseTyCdDcd: '',
    lrnTrgtGrpDtlSn: 0,
    // fnshText: '미완료',
    coldCallUseYn: '', // 콜드콜 사용여부,
    lrnPsbltHrDiv: '',

    sumupFnshYn: ''
  });

  const readies = reactive({
    course: false,
    object: false,
  });

  const learnObjects = ref([]);
  const objectResults = ref([]);

  const statusToggle = ref(false);
  const items = ref([]);
  const currentYear = new Date().getFullYear();

  const ethicsStatuses = ['2066019'];
  const digitalStatuses = ['2066023'];
  const starLinkStatuses = ['2066021'];

  const progressTypes = [lrnObjTyCds.MP4, lrnObjTyCds.MP3, lrnObjTyCds.PDF, lrnObjTyCds.Youtube, lrnObjTyCds.HTML, lrnObjTyCds.Tool];

  const getRegularStatusList = () => {
    const splCrseTyCdDcd = ethicsStatuses.includes(params.splCrseTyCdDcd) ? '2066019' : '2066013,2066014,2066015';

    getListFunc(`course/${ACT_GET_CRSE_DIST_REGULAR_LIST}`,
    {statusYn: 'Y', splCrseTyCdDcd, bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`}, items, null, () => {
    });
  }

  const setLrnRsltSn = ({lrnRsltSn, index}) => {
    objectResults.value[index].lrnRsltSn = lrnRsltSn;
  };

  const getAccumPlaySec = arr => {
    if (arr.length > 0) {
      let sum = 0;
      arr.forEach(x => {
        if (x.getValue > 0) {
          sum += x.getValue;
        }
      });
      return sum;
    }

    return 0;
  }

  const getTotProgRate = arr => {
    if (arr.length > 0) {
      let sum = 0;
      arr.forEach(x => {
        if (x.totProgRate > 0) {
          sum += x.totProgRate;
        }
      });
      return sum;
    }
    return 0;
  }

  const getLatestDt = arr => {

    if (params.splCrseTyCdDcd === '2066015' && arr.length > 0) {
      // 마지막 값
      const target = arr[arr.length -1];

      if (target.rsltMdfcnDt) {
        return target.rsltMdfcnDt;
      } else if (target.rsltRegDt) {
        return target.rsltRegDt;
      }
    }
    return 0;
  }

  const getLrnRsltSn = arr => {
    if (arr.length > 0) {
      const target = arr[arr.length -1];
      const targetPart = timestampToDatePart(target.rsltRegDt);

      if (params.splCrseTyCdDcd === '2066015') {
        if (timestampToDatePart(target.rsltRegDt).year === currentYear) {
          if (targetPart.year === 2022) {
            if (targetPart.ymd >= '20221205') {
              return target.lrnRsltSn;
            }
          } else {
            return target.lrnRsltSn;
          }
        }
      } else {
        // 직무, 판매자격의 경우 마이그레이션 데이터에 영향을 주지 않기 위해서 처리...
        if (targetPart.ymd >= '20221209') {
          return target.lrnRsltSn;
        }
      }

    }
    return 0;
  }

  const muteItem = x => {
    const results = collectionCheck(x.results, 'lrnRsltSn') ? x.results : [];
    return {
      ...x,
      totProgRate: getTotProgRate(results),
      accumPlaySec: getAccumPlaySec(results),
      progMdfcnDt: getLatestDt(results),
      lrnRsltSn: getLrnRsltSn(results),
    }
  }


  const muteItems = arrs => {
    return arrs.map(x => muteItem(x));
  }


  const getLearnResultList = () => {
    store.dispatch(`learn/${ACT_GET_LEARN_ACCUM_OBJECT_RESULT_LIST}`, {distCrseSn: distCrseSn.value, params: {splCrseTyCdDcd: params.splCrseTyCdDcd}}).then(res => {
      if(lengthCheck(res)) {
        objectResults.value = muteItems(sortObjectItems(params.crseNm, getItems(res).filter(x => progressTypes.includes(x.lrnObjTyCd))));
      }
      readies.course = true;
    });

  }

  const getLearnSumup = () => {
    ApiService.query(`/v1/app/learns/${params.distCrseSn}/results`).then(res => {
      if (lengthCheck(res)) {
        const item = getItem(res);
        params.sumupFnshYn = item.fnshYn;
      }
    })
  }

  const initLearnMetaList = () => {
    getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, () => {
      hideLoading();
      if (params.lrnTrgtGrpDtlSn > 0) {
        getLearnSumup();
        getLearnResultList();
        getListFunc(`learn/${ACT_GET_LEARN_OBJECT_MASTER_LIST}`, null, learnObjects, null, () => {readies.object = true;});
      } else {
        showMessage({
          title: '학습정보없음',
          text: '입과되지 않는 연수입니다.',
        });
        router.push({name: 'TrainRegular'});
      }
    });
  };

  // 해당 객체 재갱신
  const getLearnObjectResult = () => {
    if (targetIndex.value === -1) return;
    store.dispatch(`learn/${ACT_GET_LEARN_ACCUM_OBJECT_RESULT}`, {lrnObjDtlDistSn: objectResults.value[targetIndex.value].lrnObjDtlDistSn, params : {splCrseTyCdDcd: params.splCrseTyCdDcd}})
    .then(res => {
      if (lengthCheck(res)) objectResults.value[targetIndex.value] = muteItem(getItem(res));
      setTimeout(() => { getLearnSumup(); }, 500);
    }).catch(e => {
      console.error(e);
      setTimeout(() => { getLearnSumup(); }, 500);
    });
    // 추후 시간 확인...
  };

  // 학습 창을 위한 모듈
  const modalActive = computed(() => route.query.modal);
  const modalTitle = computed(() => {
    if (route.query.modal && targetItem.value && targetItem.value.lrnObjDtlDistSn > 0) {
      return targetItem.value.objNm;
    }
    return '학습홈';
  });

  // 학습 창을 위한 모듈
  const windowActive = ref(false);
  const windowOptions = reactive({
    answer: false,
  });
  const isLoading = ref(false);
  const targetIndex = ref(-1);
  const targetItem = ref({});
  const targetAdded = ref({});
  const targetLearnType = computed(() => {
    if (targetItem.value && targetItem.value.lrnObjDtlDistSn > 0) {
      if (isProgress(targetItem.value.lrnObjTyCd)) {
        return 'cms';
      }
    }
    return '';
  });

  const routeTarget = computed(() => {
    if ('2066023'===params.splCrseTyCdDcd) {
      return 'TrainDigital';
    }else if ('2066021'===params.splCrseTyCdDcd) {
      return 'TrainStarLink';
    }
    return 'TrainRegular';
  });

  const getObjectAdded = (callback) => {
    store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_ADDED}`, targetItem.value.lrnObjDtlDistSn).then(res => {
      if (lengthCheck(res)) {
        // 학습 결과도 같이 넣어준다.
        const added = getItem(res);
        added.lrnRsltSn = targetItem.value.lrnRsltSn;
        targetAdded.value = added;
        if (callback instanceof Function) callback();

        windowActive.value = true;
        if (isMobile.value) router.push({query: {modal: targetLearnType.value}});
      }
      isLoading.value = false;
    }).catch(e => {
      console.error(e);
      isLoading.value = false;
    });
  };

  const getDistObjParams = (target) => {
    const params = {lrnObjDtlDistSn: target.lrnObjDtlDistSn};
    if (isProgress(target.lrnObjTyCd)) {
      params.distObjSn = target.objSn;
      params.distObjNm = target.objNm;
    }
    return params;
  };

  const openLearn = ({index, finishYn}) => {
    if (cmsTypes.includes(objectResults.value[index].lrnObjTyCd)) if (!enableLearn(params.lrnPsbltHrDiv, true)) return;
    if (finishYn === 'Y') {
      openResultLearnWindow(index);
    } else {
      openLearnWindow(index);
    }

  };

  const openLearnWindow = (index, callback) => {
    if (isLoading.value) return;
    isLoading.value = true;
    const target = objectResults.value[index];
    targetIndex.value = index;
    // 윈도우 옵션들 초기화
    Object.keys(windowOptions).forEach(k => {windowOptions[k] = false;});

    if (target && target.lrnObjDtlDistSn > 0) {
      if (target.lrnRsltSn > 0) {
        targetItem.value = target;
        getObjectAdded(callback);
      } else {
        store.dispatch(`learn/${ACT_INSERT_LEARN_RESULT}`, getDistObjParams(target)).then(res => {
          if (isSuccess(res) && res.lrnRsltSn > 0) {
            setLrnRsltSn({lrnRsltSn: res.lrnRsltSn, index});
            // 적용 확인...
            target.lrnRsltSn = res.lrnRsltSn;
            targetItem.value = target;
            getObjectAdded();
          } else {
            isLoading.value = false;
          }
        }).catch(e => {
          console.error(e);
          isLoading.value = false;
        });
      }
    } else {
      targetIndex.value = -1;
      isLoading.value = false;
    }
  };

  const openResultLearnWindow = (index) => {
    // 진도율의 경우 그대로 Cms 창을 오픈하다.
    if (isProgress(objectResults.value[index].lrnObjTyCd) && canDo(objectResults.value[index], currentDate.value)) {
      openLearnWindow(index);
    }
  };

  const reloadObject = () => {
    getLearnObjectResult();
  };

  const renewStartDt = (evlBgngDt) => {
    targetAdded.value.evlExamBgngDt = evlBgngDt;
  };

  const renewEndDt = () => {
    targetAdded.value.evlExamEndDt = new Date().getTime();
    getLearnObjectResult();
  };


  showLoading();
  initLearnMetaList();

  const showStatus = () => {
    getRegularStatusList();
    statusToggle.value = true;
  };

  return {
    readies,
    currentDate,
    params,
    objectResults,
    // 학습 창을 위한 요소들 추후 모듈화 필요
    setLrnRsltSn,
    getLearnObjectResult,
    targetIndex,
    targetItem,
    targetAdded,
    targetLearnType,
    windowActive,
    windowOptions,
    renewStartDt,
    renewEndDt,
    reloadObject,
    openLearn,
    items,
    statusToggle,
    showStatus,
    modalActive,
    modalTitle,
    ethicsStatuses,
    digitalStatuses,
    starLinkStatuses,
    routeTarget
  };
}

export const learnRegularIndexSetup = (props, {emit}) => {
  const tab = ref('today');
  const currentDateStr = computed(() => timestampToDateFormat(props.currentDate.getTime(), 'full-kr'));
  const progressTypes = [lrnObjTyCds.MP4, lrnObjTyCds.MP3, lrnObjTyCds.PDF, lrnObjTyCds.Youtube, lrnObjTyCds.HTML, lrnObjTyCds.Tool];

  const renderCount = computed(() => {
    return props.items.length;
  });

  const selectTab = (selected) => {
    tab.value = selected;
  }

  const getObjectDt = (item, division) => {
    return timestampToDateFormat(item[`obj${division}Dt`], 'yyyy.MM.dd hh:mm');
  }

  const getSymbolType = (lrnObjTyCd) => {
    if(progressTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-contents';
    }
    return '';
  };

  const getScoreUnit = (item, coldCallUseYn) => {
    if(progressTypes.includes(item.lrnObjTyCd)) {
      let score;
      if(props.params.splCrseTyCdDcd === '2066015'){
        score = '';
        item.results.forEach(x => {
          score += `${Math.round(x.getValue/60)}분 `;
          if (x.rsltMdfcnDt > 0) {
            score += `(${timestampToDateFormat(x.rsltMdfcnDt, 'yyyy')}년)`
            // score += `<br>(${timestampToDateFormat(x.rsltMdfcnDt, 'yyyy.MM.dd hh:mm:ss')})`
          } else if (x.rsltRegDt > 0) {
            score += `(${timestampToDateFormat(x.rsltRegDt, 'yyyy')}년)`
            // score += `<br>(${timestampToDateFormat(x.rsltRegDt, 'yyyy.MM.dd hh:mm:ss')})`
          }
          score += `<br>`;
        });
        score += `총 학습 시간 : ${Math.round(item.accumPlaySec/60)}분`
      }else{
        const rate = item.totProgRate > 100 ? 100 : item.totProgRate;
        score = `${rate >= 95 ? 100 : Math.ceil(rate || 0)}%`;
        if (coldCallUseYn === 'Y') {
          score += `<br>(${item.coldCallRspnsDt > 0 ? '응답' : '미응답'} ${item.coldCallRspnsDt > 0 ? 1 : 0} / ${item.coldCallRound})`
        }
      }
      return score;

    }
    return '';
  }

  const openLearnWindow = (index, resultYn) => {
    emit('openLearn', {index, resultYn})
  }

  const getAddedMetas = (item) => {
    const metas = [];
    if (item.splCrseTyCdDcd === '2066015') {
      metas.push(item.objNm);
    } else {
      metas.push(item.chapNm || '-');
    }

    const tutor = getTutors(item);
    if (tutor) {
      metas.push(tutor);
    }
    return metas;
  }

  const getRangeDate = (item) => {
    const bgngDt = getObjectDt(item, 'Bgng');
    const endDt = getObjectDt(item, 'End');

    if (bgngDt && endDt) {
      return `${bgngDt} ~ ${endDt}`;
    }
    return null;
  }

  const renderItems = computed(() => props.items.map(x => ({...x, addedMetas: getAddedMetas(x), rangeDate: getRangeDate(x)})))

  return {
    tab,
    currentDateStr,
    renderCount,
    renderItems,
    getSymbolType,
    selectTab,
    canDo,
    getScoreUnit,
    getTutors,
    getObjectDt,
    openLearnWindow,
    timestampToDateFormat,
    lrnObjTyCds,
  }
}
